#azure-options {
  margin-top: 20px;
}

#region {
  margin-top: 11px;
  margin-left: 20px;
}

#azure-options-dropdown {
  margin-top: 11px;
}

#credentials {
  margin-left: 20px;
  font-size: 17px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  color: #373737;
  border: 1px solid #555555;
  border-radius: 4px;
  width: 140px;
}

#subscription-key-tip {
  margin-top: 30px;
  color: grey;
}
