#header {
  width: 100%;
  position: absolute;
  z-index: 1;
}

/* when screen height is narrow - remove the header element */
@media (max-height: 256px) {
  #header {
    display: none;
  }
}

.header-content {
  user-select: none;
}

.generic-header-logo {
  width: 22px;
  height: 22px;
  float: right;
  margin-right: 10px;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(32%) sepia(13%) saturate(0%) hue-rotate(187deg) brightness(97%) contrast(92%);
}

.generic-header-logo:hover {
  filter: brightness(0);
}

#github-logo {
  margin-top: 7px;
}

#npm-logo {
  width: 44px;
  height: 18px;
  float: right;
  margin-right: 15px;
  cursor: pointer;
  margin-top: 10px;
}
