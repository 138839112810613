#main {
  font-family: sans-serif;
  text-align: center;
  position: absolute;
  top: calc(50% - 30px);
  left: 49.5%;
  transform: translate(-50%, -50%);
}

#title {
  margin-top: 0px;
}

#text {
  width: 500px;
  height: 250px;
  border: 1px solid grey;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  overflow: auto;
  border-radius: 6px;
}

#button {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

#message-empty {
  opacity: 0;
}

#message-error {
  color: red;
}

#service-dropdown {
  margin-top: 8px;
}

.dropdown {
  font-size: 17px;
  border-radius: 4px;
  padding-left: 2px;
  padding-bottom: 3px;
  padding-top: 3px;
  color: #373737;
  cursor: pointer;
}

/* fix for incosistent style in firefox */
@-moz-document url-prefix() {
  .dropdown {
    border-radius: revert;
  }
}

@media (max-height: 700px) {
  #text {
    height: 200px;
  }
}

@media (max-height: 610px) {
  #main {
    top: calc(50% - 10px);
  }
  #text {
    height: 170px;
  }
  #button {
    margin-top: 20px;
  }
  #azure-options {
    margin-top: 0px;
  }
}

@media (max-height: 520px) {
  #text {
    margin-top: 5px;
  }
  #button {
    margin-top: 15px;
  }
  #service-dropdown {
    margin-top: 0px;
  }
}

@media (max-height: 475px) {
  #text {
    height: 20vh;
  }
}

@media (max-height: 367px) {
  #microphone {
    width: 40px;
  }
  .dropdown {
    font-size: 14px;
  }
  #subscription-key-tip {
    margin-top: 10px;
    font-size: 14px;
  }
}

@media (max-height: 320px) {
  #text {
    margin-top: 0px;
  }
  #microphone {
    width: 30px;
  }
}

@media (max-width: 575px) {
  #text {
    width: 80vw;
  }
  #title-image {
    width: 90vw;
  }
}

@media (max-width: 390px) {
  #text {
    width: 80vw;
  }
  #title-image {
    width: 90vw;
  }
}

@media (max-width: 360px) {
  #credentials {
    width: 100px;
  }
}

@media (max-width: 1350px) and (max-height: 660px) {
  #subscription-key-tip {
    display: none;
  }
}
